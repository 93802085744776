// Copyright © 2024 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run npm run copy-shared-to-packages
 */

import { addDays, setHours, setMinutes } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { Shift, UserSchedule } from '../entities';
import { formatDate } from './dateTime';

const tooltipDateFormat = 'MMM d, p';

export const getShiftDateString = (
  shift: Pick<Shift, 'startTimeHour' | 'startTimeMinutes' | 'endTimeHour' | 'endTimeMinutes'>,
  schedules: Pick<UserSchedule, 'date'>[],
  timeZone: string,
): string => {
  const { startTimeHour, startTimeMinutes, endTimeHour, endTimeMinutes } = shift;
  const date = utcToZonedTime(schedules[0].date, timeZone);
  const startDate = getShiftDate(date, startTimeHour, startTimeMinutes);
  let endDate = getShiftDate(date, endTimeHour, endTimeMinutes);
  if (startDate > endDate) {
    endDate = addDays(endDate, 1);
    return `${formatDate(startDate, tooltipDateFormat)} - ${formatDate(
      endDate,
      tooltipDateFormat,
    )}`;
  } else {
    return `${formatDate(startDate, tooltipDateFormat)} - ${formatDate(endDate, 'p')}`;
  }
};

export const getShiftDate = (date: Date, hour: number, minutes: number): Date =>
  setHours(setMinutes(date, minutes), hour);

/**
 * Gets the UTC time of the start date of the schedule.
 */
export function utcStartDateOfSchedule(
  shift: Pick<Shift, 'startTimeHour' | 'startTimeMinutes' | 'endTimeHour' | 'endTimeMinutes'>,
  userSchedule: Pick<UserSchedule, 'date'>,
  timeZone: string,
): Date | undefined {
  if (shift === null || shift === undefined) {
    return undefined;
  }
  const { startTimeHour, startTimeMinutes } = shift;
  const date = utcToZonedTime(userSchedule.date, timeZone);
  const startDate = getShiftDate(date, startTimeHour, startTimeMinutes);
  return zonedTimeToUtc(startDate, timeZone);
}

/**
 * Gets the UTC time of the end date of the schedule.
 */
export function utcEndDateOfSchedule(
  userSchedule: UserSchedule,
  timeZone: string,
): Date | undefined {
  if (userSchedule.shift === null || userSchedule.shift === undefined) {
    return undefined;
  }
  const { startTimeHour, startTimeMinutes, endTimeHour, endTimeMinutes } = userSchedule.shift;
  const date = utcToZonedTime(userSchedule.date, timeZone);
  const startDate = getShiftDate(date, startTimeHour, startTimeMinutes);
  let endDate = getShiftDate(date, endTimeHour, endTimeMinutes);
  if (startDate > endDate) {
    endDate = addDays(endDate, 1);
  }
  return zonedTimeToUtc(endDate, timeZone);
}
