// Copyright © 2024 CATTLEytics Inc.

import { utcToZonedTime } from 'date-fns-tz';
import React, { useContext, useMemo, useState } from 'react';
import { Badge, ButtonGroup, Spinner, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import AnimalTableImage from '../animals/components/AnimalTableImage';
import AlertError from '../common/components/AlertError';
import AnimalMilkingStatusBadge from '../common/components/AnimalMilkingStatusBadge';
import AnimalReproductionStatusBadge from '../common/components/AnimalReproductionStatusBadge';
import Button, { ButtonVariant } from '../common/components/Button';
import DataTable, { DataTableHeader, DataTableRow } from '../common/components/DataTable';
import LactationNumberBadge from '../common/components/LactationNumberBadge';
import TablePlaceholder from '../common/components/TablePlaceholder';
import { Sort } from '../common/enums';
import { CqlQueryResponse } from '../common/services/cqlService';
import SettingsContext from '../common/store/settings-context';
import { api, IconAddCircle, IconDelete, IconTag } from '../common/utilities';
import { camelToTitleCase } from '../common/utilities/camelCase';
import { customReportFormatDate } from '../reports/components/ReportsCustomPage';
import { Animal, AnimalGroup, AnimalIndex, ApiResourceV1, HttpMethod, QueryKey } from '../shared';

interface Props {
  animalGroupId: number;
  disableSearch?: boolean;
  disableSort?: boolean;
  hideActions?: boolean;
  notInThisGroup?: boolean;
  title?: string;
}

interface AnimalGroupResponse extends CqlQueryResponse<Animal | AnimalIndex> {
  count?: number;
  type: 'static' | 'dynamic';
}

export const AnimalGroupsAnimalTable = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const settings = useContext(SettingsContext);

  const [limit, setLimit] = useState<number>(25);
  const [offset, setOffset] = useState<number>(0);
  const [sortField, setSortField] = useState<string>('primaryTag');
  const [sortDirection, setSortDirection] = useState<Sort>(Sort.Ascending);
  const [search, setSearch] = useState<string>('');

  const notInThisGroup = !!props.notInThisGroup;

  const query = useQuery<AnimalGroupResponse>(
    [
      QueryKey.AnimalGroups,
      props.animalGroupId,
      'animals',
      notInThisGroup,
      limit,
      offset,
      sortField,
      sortDirection,
      search,
    ],
    () =>
      api<AnimalGroupResponse>(
        HttpMethod.Get,
        `${ApiResourceV1.AnimalGroups}/${props.animalGroupId}/animals`,
        {
          params: {
            limit: String(limit),
            search: String(search),
            notInThisGroup: String(notInThisGroup),
            offset: String(offset),
            sortField: sortField,
            sortDirection: String(sortDirection),
          },
        },
      ),
    { keepPreviousData: false },
  );

  const mutationSuccess = async (): Promise<void> =>
    await queryClient.invalidateQueries([QueryKey.AnimalGroups, props.animalGroupId, 'animals']);

  const mutationAdd = useMutation<unknown, unknown, number[]>(
    (ids) => {
      return api<AnimalGroup>(
        HttpMethod.Post,
        `${ApiResourceV1.AnimalGroups}/${props.animalGroupId}/animals`,
        { body: { ids } },
      );
    },
    {
      onSuccess: mutationSuccess,
    },
  );

  const mutationRemove = useMutation<unknown, unknown, number>(
    (id) => {
      return api<null>(
        HttpMethod.Delete,
        `${ApiResourceV1.AnimalGroups}/${props.animalGroupId}/animals/${id}`,
      );
    },
    {
      onSuccess: mutationSuccess,
    },
  );

  const queryClient = useQueryClient();

  const removeAnimal = async (id: number): Promise<void> => {
    try {
      await mutationRemove.mutateAsync(id);
    } catch (err) {
      console.error(err);
    }
  };
  const addAnimal = async (id: number): Promise<void> => {
    try {
      await mutationAdd.mutateAsync([id]);
    } catch (err) {
      console.error(err);
    }
  };

  const isDynamic = !query.isLoading && query.data?.type === 'dynamic';

  const headers: DataTableHeader[] = useMemo(() => {
    const columns =
      query.isLoading === false && query.data !== undefined && isDynamic === true
        ? query.data.columns
            .filter((col) => col !== 'id')
            .map((col: string) => ({
              name: col,
              label: camelToTitleCase(col),
              code: col,
              sortable: false,
            }))
        : query.isLoading === false && query.data !== undefined
        ? [
            {
              name: 'image',
              hideLabel: false,
              label: t('animalGroupsAnimalTable|imageLabel'),
              sortable: !props.disableSort,
            },
            {
              name: 'primaryTag',
              label: t('animalGroupsAnimalTable|primaryTagLabel'),
              infoTooltip: t('animalGroupsAnimalTable|primaryTagInfoTooltip'),
              classNameMobile: 'col-6',
              sortable: !props.disableSort,
            },
            {
              name: 'name',
              label: t('animalGroupsAnimalTable|nameLabel'),
              infoTooltip: t('animalGroupsAnimalTable|nameInfoTooltip'),
              classNameMobile: 'col-6',
              sortable: !props.disableSort,
            },

            {
              name: 'pen',
              label: t('animalGroupsAnimalTable|penLabel'),
              infoTooltip: t('animalGroupsAnimalTable|animalCurrentPenTooltip'),
              hideMobile: true,
              sortable: !props.disableSort,
            },
          ]
        : [];

    if (!props.hideActions) {
      return [
        ...columns,
        {
          name: 'actions',
          sortable: false,
          label: t('animalGroupsAnimalTable|actionsLabel'),
          hideMobile: true,
        },
      ];
    }

    return columns;
  }, [isDynamic, props.disableSort, props.hideActions, query.data, query.isLoading, t]);

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return <AlertError message={t('common|animalRetrievalError')} />;
  }

  const data: DataTableRow[] = !query.data
    ? []
    : isDynamic
    ? query.data.data.map((animal) => {
        return {
          ...animal,
          id: String(animal.id),
          image: <AnimalTableImage animal={animal as any} />,
          imageUrl: <AnimalTableImage animal={animal as any} />,
          primaryTag: animal.primaryTag ? (
            <>
              <IconTag className={'me-1'} style={{ fontSize: '12px' }} />
              {animal.primaryTag}
            </>
          ) : (
            '-'
          ),
          lactationGroup: animal.lactationGroup ? (
            <LactationNumberBadge lactationNumber={animal.lactationNumber as any} />
          ) : (
            '-'
          ),
          milkingStatus: <AnimalMilkingStatusBadge status={animal.milkingStatus} />,
          reproductionStatus: <AnimalReproductionStatusBadge status={animal.reproductionStatus} />,
          status: animal.status,
          birthDate: customReportFormatDate(animal.birthDate as any, settings),
          calvingDate: customReportFormatDate(animal.calvingDate as any, settings),
          calvingDueDate: customReportFormatDate((animal as any).calvingDueDate, settings),
          date: customReportFormatDate((animal as any).date, settings, false),
          dueDate: customReportFormatDate(animal.dueDate, settings, false),
          successfulBredDate: customReportFormatDate(animal.successfulBredDate, settings, false),

          name: animal.name,
          pen: animal.pen,

          actions: (
            <div onClick={(e): void => e.stopPropagation()}>
              {notInThisGroup && (
                <Button
                  onClick={(): Promise<void> => addAnimal(animal.id)}
                  size={'sm'}
                  variant={ButtonVariant.OutlinePrimary}
                >
                  <IconAddCircle className={'me-1'} />
                  {t('animalGroupsAnimalTable|addButtonLabel')}
                </Button>
              )}
              {!notInThisGroup && (
                <Button
                  onClick={(): Promise<void> => removeAnimal(animal.id)}
                  size={'sm'}
                  variant={ButtonVariant.OutlinePrimary}
                >
                  <IconDelete className={'me-1'} />
                  {t('animalGroupsAnimalTable|removeButtonLabel')}
                </Button>
              )}
            </div>
          ),
          daysInMilk: String(animal.daysInMilk ?? '-'),
          lactationGroup: animal.lactationNumber ? (
            <LactationNumberBadge lactationNumber={animal.lactationNumber} />
          ) : (
            '-'
          ),
          milkingStatus: <AnimalMilkingStatusBadge status={animal.milkingStatus} />,
          reproductionStatus: <AnimalReproductionStatusBadge status={animal.reproductionStatus} />,
          status: animal.status,
          birthDate: animal.birthDate
            ? customReportFormatDate(utcToZonedTime(animal.birthDate, 'UTC'), settings)
            : undefined,
          calvingDate: animal.calvingDate
            ? customReportFormatDate(utcToZonedTime(animal.calvingDate, 'UTC'), settings)
            : undefined,
          calvingDueDate: customReportFormatDate((animal as any).calvingDueDate, settings),
          date: customReportFormatDate((animal as any).date, settings, false),
          dueDate: customReportFormatDate(animal.dueDate, settings, false),
          successfulBredDate: customReportFormatDate(animal.successfulBredDate, settings, false),
        };
      })
    : (query.data.data as Animal[]).map((animal) => {
        return {
          id: String(animal.id),
          image: <AnimalTableImage animal={animal} />,
          primaryTag: animal.primaryTag ? (
            <>
              <IconTag className={'me-1'} style={{ fontSize: '12px' }} />
              {animal.primaryTag}
            </>
          ) : (
            '-'
          ),
          name: animal.name,
          pen: animal.pen,
          actions: (
            <div onClick={(e): void => e.stopPropagation()}>
              {notInThisGroup && (
                <Button
                  onClick={(): Promise<void> => addAnimal(animal.id)}
                  size={'sm'}
                  variant={ButtonVariant.OutlinePrimary}
                >
                  <IconAddCircle className={'me-1'} />
                  {t('animalGroupsAnimalTable|addButtonLabel')}
                </Button>
              )}
              {!notInThisGroup && (
                <Button
                  onClick={(): Promise<void> => removeAnimal(animal.id)}
                  size={'sm'}
                  variant={ButtonVariant.OutlinePrimary}
                >
                  <IconDelete className={'me-1'} />
                  {t('animalGroupsAnimalTable|removeButtonLabel')}
                </Button>
              )}
            </div>
          ),
          daysInMilk: String(animal.daysInMilk ?? '-'),
          lactationGroup: animal.lactationNumber ? (
            <LactationNumberBadge lactationNumber={animal.lactationNumber} />
          ) : (
            '-'
          ),
          milkingStatus: <AnimalMilkingStatusBadge status={animal.milkingStatus} />,
          reproductionStatus: <AnimalReproductionStatusBadge status={animal.reproductionStatus} />,
          status: animal.status,
          birthDate: animal.birthDate
            ? customReportFormatDate(utcToZonedTime(animal.birthDate, 'UTC'), settings)
            : undefined,
          calvingDate: animal.calvingDate
            ? customReportFormatDate(utcToZonedTime(animal.calvingDate, 'UTC'), settings)
            : undefined,
          calvingDueDate: customReportFormatDate((animal as any).calvingDueDate, settings),
          date: customReportFormatDate((animal as any).date, settings, false),
          dueDate: customReportFormatDate(animal.dueDate, settings, false),
          successfulBredDate: customReportFormatDate(animal.successfulBredDate, settings, false),
        };
      });

  return (
    <>
      <Stack
        className="border-bottom border-primary align-baseline mb-1"
        direction="horizontal"
        gap={2}
      >
        <h2 className="border-0 mt-2 mb-1">
          {props.title ?? t('animalGroupsManagePage|animalsInThisGroupLabel')}
        </h2>

        <ButtonGroup className="ms-auto border-0 p-0 m-0">
          <Button className=" border-0  p-0" disabled variant="outline">
            <span>{t('Records')}</span>
            <Badge className="ms-2" pill>
              {!query.isLoading ? query.data?.count : <Spinner size="sm" />}
            </Badge>
          </Button>
        </ButtonGroup>
      </Stack>
      <DataTable
        className={'mt-3'}
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isFetching}
        limit={limit}
        messageNoData={t('animalGroupsAnimalTable|noDataMessage')}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): void => history.push(`/animals/${row.id}`)}
        onSearchChange={(query): void => setSearch(query)}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        search={search}
        showSearch={!props.disableSearch}
        sortDirection={sortDirection}
        sortField={sortField}
      />
    </>
  );
};
