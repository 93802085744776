// Copyright © 2024 CATTLEytics Inc.

import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import Button from '../common/components/Button';
import DataCell from '../common/components/DataCell';
import Page from '../common/components/Page';
import Spinner from '../common/components/Spinner';
import { api, IconEdit, IconSave } from '../common/utilities';
import { CqlEditor } from '../reports/components/CqlEditor';
import { AnimalGroup, AnimalGroupType, ApiResourceV1, HttpMethod, QueryKey } from '../shared';
import { AnimalGroupsAnimalTable } from './AnimalGroupsAnimalTable';
import { AnimalGroupsModal } from './AnimalGroupsModal';

interface RouteParams {
  /**
   * Animal group ID route parameter
   */
  id: string;
}

/**
 * Routable component to manage an animal group.
 */
export const AnimalGroupsManagePage = (): JSX.Element => {
  const { t } = useTranslation();

  const { id } = useParams<RouteParams>();

  const animalGroupId = parseInt(id);

  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const [type, setType] = useState<AnimalGroupType>(AnimalGroupType.Static);

  const saveQuery = async (): Promise<void> => {
    try {
      await mutationSaveQuery.mutateAsync(query);
    } catch (err) {
      console.error(err);
    }
  };

  const queryClient = useQueryClient();

  const mutationSuccess = async (): Promise<void> =>
    await queryClient.invalidateQueries([QueryKey.AnimalGroups, Number(id), 'animals']);

  const mutationSaveQuery = useMutation<unknown, unknown, string>(
    (query) => {
      return api<AnimalGroup>(HttpMethod.Patch, `${ApiResourceV1.AnimalGroups}/${id}`, {
        body: { query },
      });
    },
    {
      onSuccess: mutationSuccess,
    },
  );

  const queryAnimalGroup = useQuery<AnimalGroup>([QueryKey.AnimalGroups, animalGroupId], () =>
    api<AnimalGroup>(HttpMethod.Get, `${ApiResourceV1.AnimalGroups}/${animalGroupId}`),
  );

  useEffect(() => {
    const data = queryAnimalGroup.data;
    if (!data) return;
    setName(data.name);
    setType(data.type);
    if (data.description) {
      setDescription(data.description);
    }
    if (data.query) {
      setQuery(data.query);
    }
  }, [queryAnimalGroup.data]);

  return (
    <Page
      breadcrumbTitle={t('animalGroupsManagePage|breadcrumbTitle')}
      breadcrumbs={[
        { label: t('entity|animal', { count: 2 }), to: '/animals/active' },
        { label: t('animalGroupsManagePage|groupsBreadcrumbTitle'), to: '/animals/groups' },
      ]}
      buttons={
        <Button
          icon={IconEdit}
          label={t('animalGroupsManagePage|editButtonLabel')}
          onClick={(): void => setEditModalVisible(true)}
        />
      }
      title={t('animalGroupsManagePage|title')}
    >
      {queryAnimalGroup.isLoading && <Spinner />}
      {!queryAnimalGroup.isLoading && (
        <>
          <Row>
            <Col md={6}>
              <DataCell label={t('animalGroupsManagePage|nameLabel')} value={name} />
            </Col>
            <Col md={6}>
              <DataCell label={t('animalGroupsManagePage|typeLabel')} value={type} />
            </Col>
          </Row>
          {description && (
            <Row>
              <Col md={12}>
                <DataCell
                  label={t('animalGroupsManagePage|descriptionLabel')}
                  value={description}
                />
              </Col>
            </Row>
          )}
        </>
      )}

      <Row>
        <Col md={6}>
          {type === AnimalGroupType.Dynamic && (
            <>
              <Stack
                className="border-bottom border-primary align-baseline mb-1"
                direction="horizontal"
                gap={2}
              >
                <h2 className="border-0 mt-2 mb-1">
                  {t('animalGroupsManagePage|availableAnimalsLabel')}
                </h2>
              </Stack>
              <CqlEditor onChange={(query): void => setQuery(query)} query={query} />
              <Button
                className={'mt-3'}
                icon={IconSave}
                label={t('animalGroupsManagePage|updateButtonLabel')}
                onClick={saveQuery}
              />
            </>
          )}
          {type === AnimalGroupType.Static && (
            <AnimalGroupsAnimalTable
              animalGroupId={animalGroupId}
              notInThisGroup={true}
              title={t('animalGroupsManagePage|availableAnimalsLabel')}
            />
          )}
        </Col>
        <Col md={6}>
          <AnimalGroupsAnimalTable
            animalGroupId={animalGroupId}
            disableSearch={type === AnimalGroupType.Dynamic}
            disableSort={type === AnimalGroupType.Dynamic}
            hideActions={type === AnimalGroupType.Dynamic}
          />
        </Col>
      </Row>
      {editModalVisible && (
        <AnimalGroupsModal
          animalGroupId={animalGroupId}
          onClose={(): void => setEditModalVisible(false)}
          onSave={(): void => setEditModalVisible(false)}
        />
      )}
    </Page>
  );
};
