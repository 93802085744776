// Copyright © 2023 CATTLEytics Inc.

import React, { useContext, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import ButtonCreate from '../common/components/ButtonCreate';
import Page from '../common/components/Page';
import AuthContext from '../common/store/auth-context';
import { IconSuperAdmin } from '../common/utilities';
import { isSiteAdminOrAbove, isSuperAdmin } from '../common/utilities';
import { isNullOrWhitespace } from '../shared';
import { JobModal } from './JobModal';
import JobsTable from './JobsTable';

interface RouteParams {
  // Tab key to make active on render.
  tabKey: string;
}

interface Props {
  className?: string;
}

/**
 * Routable component to perform some admin actions
 */
const AdminPage = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const [filters, setFilters] = useState<Record<string, string> | undefined>();

  const { tabKey } = useParams<RouteParams>();
  const history = useHistory();
  const [jobModalVisible, setJobModalVisible] = useState<boolean>(false);

  useEffect(() => {
    // this site - job's matching the current session's site id
    // global - job's with no siteId
    // all sites - job's with a siteId

    const siteId = auth.siteId ? auth.siteId : 'null';
    if (isSiteAdminOrAbove(auth) && (isNullOrWhitespace(tabKey) || tabKey === 'this-site')) {
      setFilters({ siteId: siteId.toString() });
    } else if (isSuperAdmin(auth) && tabKey === 'global') {
      setFilters({ hideSiteJobs: '1' });
    } else {
      setFilters({ hideGlobalJobs: '1' });
    }
  }, [auth, tabKey]);

  return (
    <Page
      buttons={
        <ButtonCreate
          label={t('adminPage|createButtonLabel')}
          onClick={(): void => setJobModalVisible(true)}
        />
      }
      className={props.className}
      title={t('adminPage|title')}
    >
      <Tabs
        activeKey={tabKey}
        className="mb-3"
        id="events-tabs"
        mountOnEnter={true}
        onSelect={(k): void => {
          if (k) {
            history.push(`/system/${k}`);
          }
        }}
      >
        {isSiteAdminOrAbove(auth) && (
          <Tab eventKey="this-site" title={t('adminPage|thisSiteTab')} />
        )}
        {isSuperAdmin(auth) && (
          <Tab
            eventKey="global"
            title={
              <>
                <IconSuperAdmin />
                {t('adminPage|globalTab')}
              </>
            }
          />
        )}
        {isSuperAdmin(auth) && (
          <Tab
            eventKey="all-sites"
            title={
              <>
                <IconSuperAdmin />
                {t('adminPage|allSitesTab')}
              </>
            }
          />
        )}
      </Tabs>
      <JobsTable filters={filters} />
      {jobModalVisible ? <JobModal onClose={(): void => setJobModalVisible(false)} /> : null}
    </Page>
  );
};

export default AdminPage;
