// Copyright © 2023 CATTLEytics Inc.

import { useContext, useState } from 'react';
import { Col, Placeholder, PlaceholderButton, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import CardStatsPlaceholder from '../../animals/components/CardStatsPlaceholder';
import ButtonEdit from '../../common/components/ButtonEdit';
import DataCell from '../../common/components/DataCell';
import DateTime from '../../common/components/DateTime';
import Page from '../../common/components/Page';
import TerminationCode from '../../common/entities/terminationCode';
import AuthContext from '../../common/store/auth-context';
import { api, isSuperAdmin } from '../../common/utilities';
import { ApiResourceV1, QueryKey } from '../../shared/enums';
import TerminationCodeModal from './TerminationCodeModal';

interface RouteParams {
  terminationCodeId: string;
}

const TerminationCodesDetailPage = (): JSX.Element => {
  const { t } = useTranslation();

  const { terminationCodeId } = useParams<RouteParams>();

  const auth = useContext(AuthContext);

  const [terminationCodeModalVisible, setTerminationCodeModalVisible] = useState<boolean>(false);

  const query = useQuery<TerminationCode | undefined>(
    [QueryKey.TerminationCode, 'get', terminationCodeId],
    () => api('GET', `${ApiResourceV1.TerminationCode}/${terminationCodeId}`),
    {
      refetchInterval: 300000,
      refetchOnWindowFocus: true,
    },
  );
  const item = query.data;

  return (
    <Page
      breadcrumbs={[
        { label: t('Lists'), to: '/lists' },
        { label: t('TerminationCodes'), to: '/lists/termination-codes/active' },
      ]}
      buttons={
        query.isLoading ? (
          <PlaceholderButton style={{ width: '8rem' }} xs={2} />
        ) : (
          isSuperAdmin(auth) && (
            <ButtonEdit
              label={t('Modify')}
              onClick={(): void => {
                setTerminationCodeModalVisible(true);
              }}
            />
          )
        )
      }
      className={'container'}
      title={item ? item.name : ''}
    >
      {!item && (
        <>
          <CardStatsPlaceholder />
          <Placeholder animation={'glow'}>
            {[...Array(3).keys()].map((num) => (
              <div className={'my-4'} key={num}>
                <Placeholder size={'lg'} xs={6} />
                <hr className={'my-1'} />
                <Placeholder xs={12} />
                <Placeholder xs={12} />
                <Placeholder xs={12} />
                <Placeholder xs={8} />
              </div>
            ))}
          </Placeholder>
        </>
      )}
      {item && (
        <>
          <Row></Row>

          <h2>{t('Description')}</h2>
          {item.description ? item.description : <em>{t('No description')}</em>}

          <Row>
            <Col sm={6}>
              <DataCell label={t('Created')}>
                <DateTime date={item.createdDate} />
              </DataCell>
            </Col>
            <Col sm={6}>
              <DataCell label={t('Modified')}>
                <DateTime date={item.modifiedDate} />
              </DataCell>
            </Col>
          </Row>
        </>
      )}

      {terminationCodeModalVisible && (
        <TerminationCodeModal
          editId={Number(terminationCodeId)}
          onClose={(): void => setTerminationCodeModalVisible(false)}
        />
      )}
    </Page>
  );
};

export default TerminationCodesDetailPage;
