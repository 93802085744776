// Copyright © 2023 CATTLEytics Inc.

import { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import React, { useEffect, useState } from 'react';
import { Form, PlaceholderButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import AlertError from '../../common/components/AlertError';
import ButtonSave from '../../common/components/ButtonSave';
import PlaceholderForm from '../../common/components/PlaceholderForm';
import Toast from '../../common/components/Toast';
import Setting from '../../common/entities/setting';
import { api } from '../../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey } from '../../shared/enums';
import JsonEditor from './JsonEditor';

/**
 * Parses JSON string and returns either the parsed JSON string or an error.
 */
function safeJsonParse(
  json: string | null | undefined,
):
  | { isSuccess: true; rawValue: string | null | undefined; value: string }
  | { error: unknown; isSuccess: false; rawValue: string | null | undefined } {
  try {
    const parsed = JSON.stringify(JSON.parse(String(json ?? '{}')), null, 2);
    return { isSuccess: true, value: parsed, rawValue: json };
  } catch (error) {
    return { isSuccess: false, error: error, rawValue: json };
  }
}

/**
 * Initialize the JSON map.
 */
function initializeJsonMap(
  query: string,
  setMap: React.Dispatch<React.SetStateAction<string>>,
  setErrors: React.Dispatch<React.SetStateAction<string | null>>,
): void {
  const parsedMap = safeJsonParse(String(query));

  if (parsedMap.isSuccess) {
    setMap(parsedMap.value);
  } else {
    setMap(parsedMap.rawValue ?? '');
    setErrors(String(parsedMap.error));
  }
}

/**
 * Data import tab.
 */
const DataImportTab = (): JSX.Element => {
  const { t } = useTranslation();

  const [animalImportMap, setAnimalImportMap] = useState<string>('');
  const [breedMap, setBreedMap] = useState<string>('');
  const [dateFormat, setDateFormat] = useState<string>('');
  const [eventTypeMap, setEventTypeMap] = useState<string>('');
  const [genderMap, setGenderMap] = useState<string>('');
  const [testDayImportMap, setTestDayImportMap] = useState<string>('');
  const [terminationCodeMap, setTerminationCodeMap] = useState<string>('');

  const [animalImportMapError, setAnimalImportMapError] = useState<string | null>('');
  const [breedMapError, setBreedMapError] = useState<string | null>('');
  const [dateFormatError, setDateFormatError] = useState<string | null>('');
  const [eventTypeMapError, setEventTypeMapError] = useState<string | null>('');
  const [genderMapError, setGenderMapError] = useState<string | null>('');
  const [testDayImportMapError, setTestDayImportMapError] = useState<string | null>('');
  const [terminationCodeMapError, setTerminationCodeMapError] = useState<string | null>('');

  const animalImportMapRef = React.useRef<ReactCodeMirrorRef>({});
  const breedMapRef = React.useRef<ReactCodeMirrorRef>({});
  const dateFormatRef = React.useRef<ReactCodeMirrorRef>({});
  const eventTypeMapRef = React.useRef<ReactCodeMirrorRef>({});
  const genderMapRef = React.useRef<ReactCodeMirrorRef>({});
  const testDayImportMapRef = React.useRef<ReactCodeMirrorRef>({});
  const terminationCodeMapRef = React.useRef<ReactCodeMirrorRef>({});

  const validateFields = (): boolean => {
    const newAnimalImportMapError = getJsonErrors(animalImportMap);
    const newBreedMapError = getJsonErrors(breedMap);
    const newDateFormatError = getJsonErrors(dateFormat);
    const newEventTypeMapError = getJsonErrors(eventTypeMap);
    const newGenderMapError = getJsonErrors(genderMap);
    const newTestDayImportMapError = getJsonErrors(testDayImportMap);
    const newTerminationCodeMapError = getJsonErrors(terminationCodeMap);

    setAnimalImportMapError(newAnimalImportMapError);
    setBreedMapError(newBreedMapError);
    setDateFormatError(newDateFormatError);
    setEventTypeMapError(newEventTypeMapError);
    setGenderMapError(newGenderMapError);
    setTestDayImportMapError(newTestDayImportMapError);
    setTerminationCodeMapError(newTerminationCodeMapError);

    if (newAnimalImportMapError) {
      animalImportMapRef.current.editor?.scrollIntoView();
    } else if (newBreedMapError) {
      breedMapRef.current.editor?.scrollIntoView();
    } else if (newDateFormatError) {
      dateFormatRef.current.editor?.scrollIntoView();
    } else if (newEventTypeMapError) {
      eventTypeMapRef.current.editor?.scrollIntoView();
    } else if (newGenderMapError) {
      genderMapRef.current.editor?.scrollIntoView();
    } else if (newTestDayImportMapError) {
      testDayImportMapRef.current.editor?.scrollIntoView();
    } else if (newTerminationCodeMapError) {
      terminationCodeMapRef.current.editor?.scrollIntoView();
    }

    return (
      newAnimalImportMapError === null &&
      newBreedMapError === null &&
      newDateFormatError === null &&
      newEventTypeMapError === null &&
      newGenderMapError === null &&
      newTestDayImportMapError === null &&
      newTerminationCodeMapError === null
    );
  };

  const [toastVisible, setToastVisible] = useState<boolean>(false);

  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const queryClient = useQueryClient();

  const query = useQuery<Setting | undefined>(
    [QueryKey.Settings, 'dairy-comp'],
    () => api<Setting>(HttpMethod.Get, `${ApiResourceV1.Settings}/dairy-comp`),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: true,
    },
  );

  useEffect(() => {
    if (query.data) {
      initializeJsonMap(
        String(query.data.animalImportMap),
        setAnimalImportMap,
        setAnimalImportMapError,
      );
      initializeJsonMap(String(query.data.breedMap), setBreedMap, setBreedMapError);
      setDateFormat(String(query.data.dateFormat ?? ''));
      initializeJsonMap(String(query.data.eventTypeMap), setEventTypeMap, setEventTypeMapError);
      initializeJsonMap(String(query.data.genderMap), setGenderMap, setGenderMapError);
      initializeJsonMap(
        String(query.data.testDayImportMap),
        setTestDayImportMap,
        setTestDayImportMapError,
      );
      initializeJsonMap(
        String(query.data.terminationCodeMap),
        setTerminationCodeMap,
        setTerminationCodeMapError,
      );
    }
  }, [query.data]);

  const mutation = useMutation(
    () => {
      const animalImport = JSON.stringify(JSON.parse(animalImportMap));
      const breed = JSON.stringify(JSON.parse(breedMap));
      const date = dateFormat;
      const eventType = JSON.stringify(JSON.parse(eventTypeMap));
      const gender = JSON.stringify(JSON.parse(genderMap));
      const testDayImport = JSON.stringify(JSON.parse(testDayImportMap));
      const terminationCode = JSON.stringify(JSON.parse(terminationCodeMap));

      return api('PATCH', `${ApiResourceV1.Settings}/dairy-comp`, {
        body: {
          animalImportMap: animalImport,
          breedMap: breed,
          dateFormat: date,
          eventTypeMap: eventType,
          genderMap: gender,
          testDayImportMap: testDayImport,
          terminationCodeMap: terminationCode,
        },
      });
    },
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries(QueryKey.Settings);
      },
    },
  );

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity() && validateFields();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
      setToastVisible(true);
    } catch (err) {
      console.error('Settings could not be saved.', err);
      setErrorMessage(t('animalsTab|settingsNotSavedError'));
    }
  };

  const placeholder = <PlaceholderForm fields={3} />;

  const getJsonErrors = (json: string): string | null => {
    if (json === '') {
      return String(t('dataImportTab|requiredField'));
    }
    try {
      JSON.parse(json);
      return null;
    } catch (e) {
      return String(e);
    }
  };

  return (
    <Form noValidate onSubmit={onFormSubmit} validated={validated}>
      {query.isFetching ? (
        placeholder
      ) : (
        <>
          <JsonEditor
            error={animalImportMapError}
            label={t('dataImportTab|animalImportMapLabel')}
            onChange={setAnimalImportMap}
            ref={animalImportMapRef}
            value={animalImportMap}
          />
          <JsonEditor
            error={breedMapError}
            label={t('dataImportTab|breedMapLabel')}
            onChange={setBreedMap}
            ref={breedMapRef}
            value={breedMap}
          />
          <JsonEditor
            error={dateFormatError}
            label={t('dataImportTab|dateFormatLabel')}
            onChange={setDateFormat}
            ref={dateFormatRef}
            value={dateFormat}
          />
          <JsonEditor
            error={eventTypeMapError}
            label={t('dataImportTab|eventTypeMapLabel')}
            onChange={setEventTypeMap}
            ref={eventTypeMapRef}
            value={eventTypeMap}
          />
          <JsonEditor
            error={genderMapError}
            label={t('dataImportTab|genderMapLabel')}
            onChange={setGenderMap}
            ref={genderMapRef}
            value={genderMap}
          />
          <JsonEditor
            error={testDayImportMapError}
            label={t('dataImportTab|testDayImportMapLabel')}
            onChange={setTestDayImportMap}
            ref={testDayImportMapRef}
            value={testDayImportMap}
          />
          <JsonEditor
            error={terminationCodeMapError}
            label={t('dataImportTab|terminationCodeMapLabel')}
            onChange={setTerminationCodeMap}
            ref={terminationCodeMapRef}
            value={terminationCodeMap}
          />
        </>
      )}

      {errorMessage ? <AlertError message={errorMessage} /> : null}

      <p className={'d-flex justify-content-center'}>
        {query.isFetching ? (
          <PlaceholderButton xs={2} />
        ) : (
          <ButtonSave busy={mutation.isLoading} disabled={mutation.isLoading} />
        )}
      </p>
      <Toast onClose={(): void => setToastVisible(false)} show={toastVisible}>
        {t('dataImportTab|settingSavedToast')}
      </Toast>
    </Form>
  );
};

export default DataImportTab;
