// Copyright © 2024 CATTLEytics Inc.

import { differenceInBusinessDays } from 'date-fns';
import { PropsWithChildren, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import AlertError from '../common/components/AlertError';
import DataTable, { DataTableHeader, DataTableRow } from '../common/components/DataTable';
import DateTime from '../common/components/DateTime';
import TablePlaceholder from '../common/components/TablePlaceholder';
import { Sort } from '../common/enums';
import { api } from '../common/utilities';
import { ApiResourceV1, QueryKey } from '../shared';

interface Props {
  /**
   * Additional CSS classes to add to component.
   */
  className?: string;

  /**
   * Keys to filter data.
   */
  filters?: Record<string, string>;

  /**
   * Number of jobs to show.
   */
  initialLimit?: number;

  /**
   * Number of jobs to skip.
   */
  initialOffset?: number;

  /**
   * Table sort direction.
   */
  initialSortDirection?: Sort;

  /**
   * Table sort field.
   */
  initialSortField?: string;
}

/**
 * A table component containing job data.
 */
const SyncTable = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const filter: undefined | Record<string, string> = props.filters;
  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'maxEventDate');
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Descending,
  );

  const query = useQuery(
    [QueryKey.SiteSyncStatus, filter, limit, offset, sortField, sortDirection],
    () =>
      api<{ completedOn: Date; maxEventDate: Date; name: string; siteId: number }[]>(
        'GET',
        ApiResourceV1.SiteSyncStatus,
        {
          params: {
            limit: String(limit),
            offset: String(offset),
            sortField: sortField,
            sortDirection: String(sortDirection),
          },
        },
      ),
    { enabled: true, keepPreviousData: true, refetchInterval: 15000 },
  );

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return (
      <AlertError
        message={t('common|unexpectedRetrievalError', {
          value: t('syncTable|error'),
        })}
      />
    );
  }

  const headers: DataTableHeader[] = [
    {
      name: 'id',
      label: t('syncTable|siteId'),
      className: 'col-1',
    },
    {
      name: 'name',
      label: t('syncTable|name'),
      className: 'col-7',
    },
    {
      name: 'maxEventDate',
      label: t('syncTable|maxEventDate'),
      className: 'col-1',
    },
    {
      name: 'completedOn',
      label: t('syncTable|completedOn'),
      className: 'col-1',
    },
    {
      name: 'maxEventDateStatus',
      label: t('syncTable|maxEventDateStatus'),
      className: 'col-1',
    },
    {
      name: 'completedOnStatus',
      label: t('syncTable|completedOnStatus'),
      className: 'col-1',
    },
  ];

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((row) => ({
        id: String(row.siteId),
        name: <small>{row.name}</small>,
        maxEventDate: (
          <small>
            <DateTime date={row.maxEventDate} />
          </small>
        ),
        completedOn: (
          <small>
            <DateTime date={row.completedOn} />
          </small>
        ),
        maxEventDateStatus: !row.maxEventDate ? (
          <small>
            <Badge bg="danger">No events</Badge>
          </small>
        ) : differenceInBusinessDays(new Date(), new Date(row.maxEventDate)) > 3 ? (
          <small>
            <Badge bg="warning">
              {differenceInBusinessDays(new Date(), new Date(row.maxEventDate))} days out of date
            </Badge>
          </small>
        ) : (
          <small>
            <Badge bg="success">Active</Badge>
          </small>
        ),
        completedOnStatus: !row.completedOn ? (
          <small>
            <Badge bg="danger">No Imports found</Badge>
          </small>
        ) : differenceInBusinessDays(new Date(), new Date(row.completedOn)) > 3 ? (
          <small>
            <Badge bg="warning">
              {differenceInBusinessDays(new Date(), new Date(row.completedOn))} days out of date
            </Badge>
          </small>
        ) : (
          <small>
            <Badge bg="success">Active</Badge>
          </small>
        ),
      }));

  return (
    <div>
      <DataTable
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={t('jobsTable|noDataFoundMessage', {
          value: t('entity|job', { count: 1 }),
        })}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />
    </div>
  );
};

export default SyncTable;
